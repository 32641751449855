
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/documents/sales-invoice/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiDocumentsSalesInvoice } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { SalesInvoiceTable } from "@/core/directive/interface/salesInvoice";
import store from "@/store";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "documents-sales-invoice",
  components: {
    MBDatatable,
    FilterDropdown,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<SalesInvoiceTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([]);
    const downloadDisabled = ref<boolean>(false);
    let childWin: any;
    const tableHeader = ref([
      {
        name: t("salesInvoice.invoiceNumber"),
        key: "invoice_number",
        sortable: true,
      },
      {
        name: t("salesInvoice.invoiceDate"),
        key: "invoice_date",
        sortable: true,
      },
      {
        name: t("salesInvoice.orderNumber"),
        key: "order_number",
        sortable: false,
      },
      {
        name: t("salesInvoice.orderDate"),
        key: "order_date",
        sortable: true,
      },
      {
        name: t("salesInvoice.billToName"),
        key: "bill_to",
        sortable: false,
      },
      {
        name: t("salesInvoice.shipToName"),
        key: "ship_to",
        sortable: false,
      },
      {
        name: t("salesInvoice.grandTotalBase"),
        key: "grand_total_base",
        sortable: false,
        align: "right",
      },
      {
        name: t("salesInvoice.grandTotalPurchase"),
        key: "grand_total_purchase",
        sortable: false,
        align: "right",
      },
      {
        name: t("salesInvoice.revenueConfirmTime"),
        key: "revenue_confirm_time",
        sortable: false,
      },
      {
        name: t("salesInvoice.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const getSalesInvoiceList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiDocumentsSalesInvoice.getSalesInvoiceList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.downloadDisabled = false;
              item.showDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getSalesInvoiceList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (
            item == "invoice_date_start" ||
            item == "revenue_confirm_time_start"
          ) {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (
            item == "invoice_date_end" ||
            item == "revenue_confirm_time_end"
          ) {
            filterArr.push({
              field: item,
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getSalesInvoiceList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getSalesInvoiceList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      let statusClass = "";
      switch (status) {
        case "New":
        case "Sell On Credit":
          statusClass = "badge-light-primary";
          break;
        case "Processing":
        case "Pending":
          statusClass = "badge-light-warning";
          break;
        case "Partial Shipped":
        case "Shipped":
          statusClass = "badge-light-info";
          break;
        case "Complete":
        case "Paid":
          statusClass = "badge-light-success";
          break;
        case "Refunded":
          statusClass = "badge-light-danger";
          break;
        case "Canceled":
          statusClass = "badge-light-dark";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const fileExport = async (id, item) => {
      item.downloadDisabled = true;
      const data = await ApiDocumentsSalesInvoice.print({
        id: id,
        download_type: "stream",
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    const fileShow = (id, item) => {
      item.showDisabled = true;
      ApiDocumentsSalesInvoice.print2({
        id: id,
        download_type: "url",
      })
        .then(({ data }) => {
          item.showDisabled = false;
          childWin = window.open(
            data.data.url,
            "newwindow",
            "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          );
          childWin.onload = function () {
            // childWin.print();
          };
        })
        .catch((error) => {
          item.showDisabled = false;
          console.log(error);
        });
    };

    return {
      t,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      downloadDisabled,
      getSalesInvoiceList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      fileExport,
      fileShow,
    };
  },
});
